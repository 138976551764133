<template>
    <div class="app" @click="touchDisplay">
        <!--<video width="100%" height="100%" muted :poster="require(`@/assets/img/main/${preview}`)" autoplay loop><source :src="require(`@/assets/video/${video}`)" type="video/mp4" codecs="mp4" /></video>-->
        <swiper
            class="blocks"
            :modules="[Autoplay, EffectFade]"
            :autoplay="autoplay"
            effect="fade"
            :slides-per-view="1"
            :space-between="0"
            @swiper="onSwiper"
            @slideChange="onSlideChange"
        >
            <swiper-slide v-for="image in images" :key="image.id">
                <img :src="require(`@/assets/img/main/${image.img}`)" :alt="image.img" />
            </swiper-slide>
        </swiper>
    </div>
</template>

<script>
import { EffectFade, Autoplay } from "swiper";
import { Swiper, SwiperSlide } from "swiper/vue";
import "swiper/css";
import "swiper/css/autoplay";
import "swiper/css/effect-fade";
export default {
    name: "t-main",
    components: {
        Swiper,
        SwiperSlide,
    },
    setup() {
        const onSwiper = (swiper) => {
            console.log(swiper);
        };
        const onSlideChange = () => {
            console.log("slide change");
        };
        return {
            onSwiper,
            onSlideChange,
            EffectFade,
            Autoplay,
        };
    },
    data() {
        return {
            /*video: `fps.mp4`,
      preview: `1.jpg`,*/
            images: [
                {
                    id: 0,
                    img: "saida.jpg",
                },
                {
                    id: 1,
                    img: "time.png",
                },
                {
                    id: 2,
                    img: "team.png",
                },
                {
                    id: 3,
                    img: "dreams.jpg",
                },
                {
                    id: 4,
                    img: "your_gift.png",
                },
                {
                    id: 5,
                    img: "season_menu.jpg",
                },
                {
                    id: 6,
                    img: "yandex.png",
                },
                {
                    id: 7,
                    img: "new3.png",
                },
            ],
            effect: "fade",
            grabCursor: true,
            loop: true,
            centeredSlides: true,
            slidesPerView: "auto",
            autoplay: {
                delay: 8000,
                disableOnInteraction: false,
            },
            now: new Date(),
        };
    },
    methods: {
        touchDisplay() {
            this.$router.push("/welcome");
        },
    },
    computed: {
        currentDate() {
            return this.now.getDate() + "." + this.now.getMonth() + 1 + "." + this.now.getFullYear();
        },
    },
    mounted() {
        localStorage.removeItem("orderId");
        let host = window.location.href;
        let cafe_slug = host.split("/");
        this.cafe_slug = cafe_slug[2].split(".")[0];
        if (host.indexOf("localhost") != -1) {
            this.cafe_slug = "it-park";
        }
        console.log(this.cafe_slug);
        // if (this.cafe_slug == "pushkina") {
        //     this.images.splice(7, 1);
        // }
        /*if (this.now.getDate() > 25 && this.now.getMonth() + 1 === 10) {
            this.images.shift();
        }*/
        if (this.cafe_slug === "it-park") {
            this.images.splice(0, 2);
        }
    },
};
</script>

<style lang="scss">
.app {
    position: relative;
    img,
    video {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
    .footer {
        position: absolute;
        bottom: 0;
    }
}
</style>
